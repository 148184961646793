<section class="home-hero">
    <div class="container">
        <div class="row home-hero__row">
            <div class="col-lg-6 col-12">
                <h1 class="section__title">Comunidades seguras y conectadas.</h1>

                <div class="swiper-container home-hero__swiper"
                    [swiper]="textSwiper.config"
                    [(index)]="textSwiper.index"
                    (slideChangeTransitionStart)="slideChanged()"
                    >
                    <div class="swiper-wrapper">
                        <div class="swiper-slide">
                            <p class="home-hero__paragraph">Interconectamos administradores,
                                guardias, residentes e invitados para brindar soluciones de seguridad y
                                comunicación comunitaria.
                            </p>
                        </div>
                    </div>
                </div>

                <a [routerLink]="['/contact-us']" routerLinkActive="router-link-active" class="blue-btn" (click)="sendEventToAmplitude()">
                    <span class="btn__text">Solicitar una demo</span>
                </a>
            </div>

            <div class="col-lg-6 col-12">
                <!-- <div class="swiper-container home-hero__img-swiper" [swiper]="imgSwiper.config"
                    [(index)]="imgSwiper.index" (slideChangeTransitionStart)="slideImgChanged()">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide">
                            <img src="assets/img/home-hero/1.png" class="img-fluid" alt="Munily">
                        </div>
                        <div class="swiper-slide">
                            <img src="assets/img/home-hero/2.png" class="img-fluid" alt="Munily">
                        </div>
                        <div class="swiper-slide">
                            <img src="assets/img/home-hero/3.svg" class="img-fluid" alt="Munily">
                        </div>
                        <div class="swiper-slide">
                            <img src="assets/img/home-hero/4.svg" class="img-fluid" alt="Munily">
                        </div>
                    </div>
                </div> -->
                <img src="assets/img/home-hero/1-min.jpg" class="img-fluid" alt="Munily">
            </div>

            <div class="col-12 home-hero__mobile-title">
                <h1 class="section__title">Comunidades seguras y conectadas.</h1>
            </div>
        </div>
    </div>

    <!-- <img src="assets/img/home-hero/top-curved-path.svg" alt="" class="home-hero__end-section"> -->
</section>
