import { Injectable } from '@angular/core';
import amplitude from 'amplitude-js';
import { environment } from '../../environments/environment';
import { AmplitudeEvent, AmplitudeGenericEvent, WEB_PAGE_ID } from '../interfaces/amplitude';

@Injectable({
  providedIn: 'root',
})
export class AmplitudeService {
  constructor() {}

  sendToAmplitude(event: AmplitudeGenericEvent) {
    if (environment.amplitudeEnabled) {
      const instance = amplitude.getInstance();
      instance.init(environment.amplitudeApiKey, WEB_PAGE_ID);
      instance.logEvent(event.event_type, event.event_properties);
    }
  }

  handleVehiclePlateRegistrationQpass(userId: string) {
    this.sendToAmplitude({ event_type: AmplitudeEvent.vehiclePlateRegistrationQpass, user_id: userId })
  }

  handleRegistrationPedestrianQpass(userId: string) {
    this.sendToAmplitude({ event_type: AmplitudeEvent.pedestrianEntryRegisteredQpass, user_id: userId })
  }
}
